import React, { Component } from "react"

interface Props {
  videoUrl: string
}

export default class HeroVideo extends Component<Props> {
  render() {
    return (
      <div className="max-w-6xl mx-auto my-12 px-6 lg:px-8 ">
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            className="w-full rounded-xl"
            src={this.props.videoUrl}
            title="Arus Oil Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    )
  }
}
