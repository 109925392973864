import React, { Component, ReactElement } from "react"

export interface Feed {
  iconType: ReactElement
  iconClass: string
  content: string
  step: string
  description?: string
  image?: string
}

interface Props {
  content: Feed[]
  bold?: boolean
}

export default class Feeds extends Component<Props> {
  renderFeeds = () => {
    const feedView: any = []
    const contentLength = this.props.content.length

    this.props.content.map((eachContent, index: number) => {
      let defaultClassName =
        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
      if (eachContent.iconClass) {
        defaultClassName += ` ${eachContent.iconClass}`
      }

      feedView.push(
        <li key={index}>
          <div className="relative pb-8">
            {index + 1 < contentLength && (
              <span
                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              ></span>
            )}
            <div className="relative flex space-x-3">
              <div>
                <span className={defaultClassName}>{eachContent.iconType}</span>
              </div>
              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p
                    className={`text-sm text-gray-500 ${
                      this.props.bold ? "font-bold" : ""
                    }`}
                  >
                    {eachContent.content}
                  </p>
                  {eachContent.description && (
                    <p className="mt-2 text-sm text-gray-500">
                      {eachContent.description}
                    </p>
                  )}
                  {eachContent.image && (
                    <img
                      className="mt-4 w-36 sm:w-56 h-auto"
                      src={eachContent.image}
                      alt=""
                    />
                  )}
                </div>
                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                  {eachContent.step}
                </div>
              </div>
            </div>
          </div>
        </li>
      )
      return null
    })
    return feedView
  }

  render() {
    return <ul className="-mb-8">{this.renderFeeds()}</ul>
  }
}
