import React, { Component } from "react"
import LogoIOI from "../../images/logo_ioi.png"
import LogoLotus from "../../images/logo_lotus.png"
import LogoMajestic from "../../images/logo_majestic.png"
import LogoStripes from "../../images/logo_stripes.png"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

interface Props {
  language: Language
}

export default class Clients extends Component<Props> {
  renderPartners = () => {
    const partnerView: any = []
    const partners = [
      {
        image: LogoIOI,
        imageAlt: "IOI",
        link: "https://www.ioiproperties.com.my/",
      },
      {
        image: LogoMajestic,
        imageAlt: "Majestic Hotel",
        link: "https://www.majestickl.com/",
      },
      {
        image: LogoStripes,
        imageAlt: "Stripes Hotel",
        link: "https://www.stripeskl.com/",
      },
      {
        image: LogoLotus,
        imageAlt: "Lotus",
        link: "https://lotusgroup.com.my/",
      },
    ]

    partners.map((eachPartner, index: number) => {
      partnerView.push(
        <div
          className="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
          key={index}
        >
          <a target="_blank" rel="noreferrer" href={eachPartner.link}>
            <img
              className="max-h-12"
              src={eachPartner.image}
              alt={eachPartner.imageAlt}
            />
          </a>
        </div>
      )
      return null
    })

    return partnerView
  }

  render() {
    const language = this.props.language
    return (
      <div className="mt-32">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                {localeContent(language).clients.paragraph1}
              </h2>
              <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                {localeContent(language).clients.paragraph2}
              </p>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
              {this.renderPartners()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
